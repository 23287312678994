import httpManager from "../axios/httpManager";

export function getLeisureAttrList () {
    let url = '/leisuerconfig/attr_list';
    return httpManager.get(url);
}
export function getLeisureAttrById (idx) {
    return httpManager.get('/leisuerconfig/attr_get_by_id?idx=' + idx)
}
export function updateLeisureAttr (leisuerAttribute) {
    return httpManager.post('/leisuerconfig/attr_update', leisuerAttribute);
}


export function getLeisureKindList () {
    let url = '/leisuerconfig/kind_list';
    return httpManager.get(url);
}
export function getLeisureKindById (idx) {
    return httpManager.get('/leisuerconfig/kind_get_by_id?idx=' + idx)
}
export function updateLeisureKind (leisureKind) {
    return httpManager.post('/leisuerconfig/kind_update', leisureKind);
}