import httpManager from "../axios/httpManager";

export function getRegisterCodeList (search, pageNum, pageSize) {
    let url = '/code/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}

export function getRegisterCodeById (id) {
    return httpManager.get('/code/get_by_id?id=' + id);
}
export function getRegisterCodeByCode (code) {
    return httpManager.get('/code/get_by_code?code=' + code);
}

export function updateRegisterCode (entity) {
    return httpManager.post('/code/update', entity);
}